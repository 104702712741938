export default {
  // 内容
  addNodeInstruction: `
You cannot add nodes directly on the web interface in Crawlab.

Adding a node is quite simple. The only thing you have to do is to run a Crawlab service on your target machine.

For details, please refer to the [Multi-Node Deployment Documentation](https://docs.crawlab.cn/Installation/MultiNode.html).
`,
  auth: {
    loginExpiredMessage: 'You have been logged out, you can cancel to stay on this page, or log in again',
    loginExpiredTitle: 'Confirm logout',
    loginExpiredConfirm: 'Confirm',
    loginExpiredCancel: 'Cancel'
  }
};
